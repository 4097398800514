import React from "react";
import { useLoaderData } from "react-router-dom";
import { Article } from "../types";
import styles from "./ArticlesPage.module.scss";
import getMediaUrl from "../../../common/utils/getMediaUrl";
import { ROUTE_ARTICLES } from "../routes";
import PostInfo from "../components/PostInfo";
import { Link } from "react-router-dom";
import Socials from "../components/Socials";
import MetaTags from "../../../layout/MetaTags";

const ArticlesPage = (...props: any) => {
  const data: any = useLoaderData();
  return (
    <div className={"content"}>
      <MetaTags
        title={`Ski Tips & Guides | SNOMAD`}
        description={`Ski Tips & Guides | SNOMAD`}
      />
      <h1>All Posts</h1>
      {data.articles.map((article: Article) => {
        return (
          <Link
            to={`${ROUTE_ARTICLES}/${article.slug}`}
            key={article.id}
            className={styles.ArticleLink}
          >
            <div className={styles.articlePreview}>
              <img
                src={getMediaUrl(article.image.attributes.url)}
                className={styles.image}
              />
              <div className={styles.previewText}>
                <PostInfo article={article} />
                <div className={styles.articleContent}>
                  <h2 className={styles.title}>{article.title}</h2>
                  <p>{article.preview}</p>
                </div>
                {/*<Socials />*/}
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default ArticlesPage;
