import React from "react";
import styles from "../pages/ArticlesPage.module.scss";
import { ReactComponent as Logo } from "../icons/crown.svg";
import formatDate from "../../../common/utils/formatDate";
import { Article } from "../types";
// @ts-ignore
import readingTime from "reading-time/lib/reading-time";

interface Props {
  article: Article;
}
const PostInfo = ({ article }: Props) => {
  const timeToReadMins = Math.max(readingTime(article.content).minutes, 1);
  return (
    <div className={styles.articleInfo}>
      <div className={styles.profilePicture}>
        <img src={"/dan-profile.jpg"} alt={"Dan Lever profile picture"} />
      </div>
      <div className={styles.articleInfoText}>
        <p>
          <span>Dan Lever</span>
          <span className={styles.icon}>
            <Logo />
          </span>
        </p>
        <p className={styles.date}>
          {formatDate(article.publishedAt)} - {timeToReadMins} mins
        </p>
      </div>
    </div>
  );
};

export default PostInfo;
