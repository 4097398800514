import React from "react";
import styles from "./ResortReviewPreview.module.scss";
import noFaceAvatar from "./default-avatar.webp";
import { ResortRating } from "../../types";
import Rating from "../../../../common/components/Rating";

interface Props {
  resortRating: ResortRating;
}

const ResortReviewPreview = ({ resortRating }: Props) => {
  return (
    <div className={styles.reviewPreview}>
      <div className={styles.posterInfo}>
        <img src={noFaceAvatar} alt="No user avatar" />
        <div>
          <div>{resortRating.userName}</div>
          <Rating rating={resortRating.ratingOverall} />
        </div>
      </div>
      <div className={styles.reviewText}>{resortRating.reviewText}</div>
    </div>
  );
};

export default ResortReviewPreview;
