import { currencyCodes } from "../consts/currencyCodes";

const formatCurrency = (
  currencyCode: string,
  priceLocalUnits?: number | null,
): string => {
  if (!priceLocalUnits) {
    return "";
  }
  const currencySymbol: string = currencyCodes[currencyCode] ?? "";
  return `${currencySymbol}${priceLocalUnits}`;
};

export const getFormatCurrency = (currencyCode: string) => {
  return (priceLocalUnits?: number | null) => {
    return formatCurrency(currencyCode, priceLocalUnits);
  };
};

export default formatCurrency;
