const formatDistance = (
  distanceUnits: string | null,
  distanceInLocalUnits?: number | null,
): string => {
  if (!distanceInLocalUnits) {
    return "";
  }

  return `${distanceInLocalUnits}${distanceUnits ?? ""}`;
};

export const getFormatDistance = (distanceUnits: string | null) => {
  return (distanceInLocalUnits?: number | null) => {
    return formatDistance(distanceUnits, distanceInLocalUnits);
  };
};

export default formatDistance;
