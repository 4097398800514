import React from "react";
import { CompiledResort } from "../types";
import styles from "./ResortsLeague.module.scss";
import classNames from "classnames";
import ResortCard from "./ResortCard";

interface Props {
  sortedResorts: CompiledResort[];
  hideOrdinals?: boolean;
}

const ResortsLeagueTable = ({ sortedResorts, hideOrdinals = false }: Props) => {
  return (
    <div className={classNames(styles.section, "content")}>
      <div className={classNames(styles.grid)}>
        {sortedResorts.map((resort, index) => {
          const position = index + 1;
          return (
            <ResortCard
              resort={resort}
              key={resort.id}
              position={hideOrdinals ? undefined : position}
            />
          );
        })}
      </div>
    </div>
  );
};
export default ResortsLeagueTable;
