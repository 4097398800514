import { reviewsClient } from "../../../../common/strapi/strapiClient";

type Fields = { [key: string]: string | number };

export const updateReview = async (reviewId: number, fields: Fields) => {
  const response = await reviewsClient.put(`/api/resort-ratings/${reviewId}`, {
    data: {
      ...fields,
    },
  });
  console.log({ response });
  return response.data.data;
};
const submitReview = async (skiResortId: number, fields: Fields) => {
  const response = await reviewsClient.post("/api/resort-ratings", {
    data: {
      skiResort: skiResortId,
      ...fields,
    },
  });
  console.log({ response });
  return response.data.data;
};

export default submitReview;
