import React from "react";
import { useLoaderData } from "react-router-dom";
import Markdown from "react-markdown";
import { ResortsPageType } from "../data/fetchPage";
import ResortsLeagueTable from "../components/ResortsLeagueTable";
import styles from "./ResortsLeagueTablePage.module.scss";
import Splash from "../../../common/components/Splash";
import MetaTags from "../../../layout/MetaTags";

const ResortsLeagueTablePage = () => {
  const { resortLeagueTable } = useLoaderData() as ResortsPageType;

  if (!resortLeagueTable) {
    return <div>not found</div>;
  }
  return (
    <>
      <MetaTags
        title={resortLeagueTable.seoTitle}
        description={
          "10 Top-Rated ski resorts in the World based on skier ratings and reviews"
        }
      />
      <Splash
        splashText={resortLeagueTable.name}
        splashImage={resortLeagueTable.splashImage}
        tagline={resortLeagueTable.tagline}
      />
      <div className={`content ${styles.section}`}>
        <Markdown>{resortLeagueTable.description}</Markdown>
      </div>
      <ResortsLeagueTable sortedResorts={resortLeagueTable.sortedResorts} />
    </>
  );
};

export default ResortsLeagueTablePage;
