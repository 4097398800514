import React from "react";
import styles from "./FormStyles.module.scss";

interface Props {
  name: string;
  label: string;
  isRequired?: boolean;
  value: boolean | string;
  updateValue: any;
}

const CheckboxRow = ({
  name,
  label,
  isRequired = false,
  value,
  updateValue,
}: Props) => {
  return (
    <div className={`${styles.formRow} ${styles.checkboxRow}`}>
      <div>
        <input
          type={"checkbox"}
          checked={Boolean(value)}
          name={name}
          onChange={updateValue}
        />
      </div>
      <label
        htmlFor={name}
        className={styles.label}
        onClick={() => {
          const newValue = !value;
          updateValue({
            target: {
              type: "checkbox",
              checked: newValue,
            },
          });
        }}
      >
        {label}
        {isRequired ? <span className={styles.isRequired}>*</span> : ""}
      </label>
    </div>
  );
};

export default CheckboxRow;
