import React from "react";
import styles from "./ResortStats.module.scss";
import { Resort } from "../../types";
import booleanYesNo from "../../../../common/utils/booleanYesNo";
import { getFormatCurrency } from "../../../../common/utils/formatCurrency";
import { getFormatDistance } from "../../../../common/utils/formatDistance";

interface Props {
  resort: Resort;
}

type Stat =
  | undefined
  | { icon?: string; label: string; value: number | string | null };
type Section = {
  name: string;
  stats: Stat[];
  info?: string;
};

const getStat = (label: string, value: string | number | null): Stat => {
  if (!value || value === "") {
    return undefined;
  }

  return {
    label,
    value,
  };
};

const ResortStats = ({ resort }: Props) => {
  const formatCurrency = getFormatCurrency(resort.location.localCurrencyCode);
  const formatDistance = getFormatDistance(resort.location.localUnitsDistance);
  const formatElevation = getFormatDistance(
    resort.location.localUnitsElevation,
  );
  // const formatElevation
  const sections: Section[] = [
    {
      name: "Ski Terrain",
      stats: [
        getStat("Beginner Runs", resort.terrainNumRunsBeginner),
        getStat("Intermediate Runs", resort.terrainNumRunsIntermediate),
        getStat("Advanced Runs", resort.terrainNumRunsAdvanced),
        getStat(
          "Longest Run",
          formatDistance(resort.terrainLongestRunLocalUnits),
        ),
        getStat(
          "Skiable Terrain",
          formatDistance(resort.terrainTotalDistanceLocalUnits),
        ),
      ],
    },
    {
      name: "Elevation",
      stats: [
        getStat("Top", formatElevation(resort.elevationTopLocalUnits)),
        getStat("Bottom", formatElevation(resort.elevationBottomLocalUnits)),
        getStat(
          "Vertical Drop",
          formatElevation(
            resort.elevationTopLocalUnits && resort.elevationBottomLocalUnits
              ? resort.elevationTopLocalUnits - resort.elevationBottomLocalUnits
              : null,
          ),
        ),
      ],
    },
    {
      name: "Lifts",
      stats: [
        getStat("Gondolas / Cable Cars", resort.numLiftsGondolasCableCars),
        getStat("Chairlifts", resort.numLiftsChairlifts),
        getStat("Surface Lifts", resort.numLiftsDragLifts),
      ],
    },
    {
      name: "Lift Pass Costs",
      info: "*Prices are for a full-priced, top-level adult pass during peak season, to allow for easy comparison between resorts.",
      stats: [
        getStat(
          "Season pass",
          formatCurrency(resort.liftPassCostSeasonLocalCurrency),
        ),
        getStat(
          "6 days",
          formatCurrency(resort.liftPassCost6daysLocalCurrency),
        ),
        getStat("1 day", formatCurrency(resort.liftPassCost1dayLocalCurrency)),
      ],
    },
    {
      name: "Weather",
      stats: [
        getStat(
          "Average annual snowfall",
          formatElevation(resort.averageSnowfallLocalUnits),
        ),
        getStat("Snow-sure", booleanYesNo(resort.isSnowsure)),
        getStat("No. of sunny days last season", resort.numDaysSunSeason),
        getStat("Snow-making", booleanYesNo(resort.hasSnowmaking)),
      ],
    },
  ];
  return (
    <div className={styles.resortStats}>
      <div className="pageTitle">Resort Stats</div>
      {sections
        .filter((section) => {
          return section.stats.some((stat) => stat && stat.value);
        })
        .map((section) => {
          return (
            <div className={styles.section} key={section.name}>
              <div>{section.name}</div>
              <div className={styles.stats}>
                {section.stats.map((stat) => {
                  if (!stat || stat.value === null) {
                    return "";
                  }
                  return (
                    <div className={styles.resortStat} key={stat.label}>
                      {stat.label} /{stat.value}
                    </div>
                  );
                })}
              </div>
              {section.info ? <div>{section.info}</div> : ""}
            </div>
          );
        })}
    </div>
  );
};

export default ResortStats;
