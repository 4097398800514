import React, { ChangeEventHandler } from "react";
import styles from "./FormStyles.module.scss";

interface Props {
  name: string;
  label: string;
  isRequired?: boolean;
  options: { value: string; name: string }[];
  value: string;
  updateValue: ChangeEventHandler<HTMLSelectElement>;
}

const SelectRow = ({
  name,
  label,
  isRequired = false,
  options = [],
  value,
  updateValue,
}: Props) => {
  return (
    <div className={styles.formRow}>
      <label htmlFor={name} className={styles.label}>
        {label}
        {isRequired ? <span className={styles.isRequired}>*</span> : ""}
      </label>
      <select name={name} value={value} onChange={updateValue}>
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectRow;
