import React from "react";
import { StrapiImage } from "../strapi/types";
import styles from "./Splash.module.scss";
import getMediaUrl from "../utils/getMediaUrl";

interface Props {
  splashText: string;
  tagline?: string | React.ReactNode;
  splashImage?: StrapiImage | null;
}
const Splash = ({ splashText, tagline, splashImage }: Props) => {
  return (
    <div
      className={styles.splash}
      style={{
        backgroundImage: splashImage
          ? `url("${getMediaUrl(splashImage.attributes.url)}")`
          : undefined,
        minHeight: splashImage ? "220px" : undefined,
      }}
    >
      <span className={styles.splashTitle}>{splashText}</span>
      {tagline ? <span className={styles.tagline}>{tagline}</span> : ""}
    </div>
  );
};

export default Splash;
