import React from "react";
import { Link, useLoaderData } from "react-router-dom";
import styles from "./HomePage.module.scss";
import { CompiledResort } from "../../Resorts/types";
import getMediaUrl from "../../../common/utils/getMediaUrl";
import InfoCard from "../../../common/components/InfoCard";
import { HomePageData } from "../data/fetchPage";
import ResortCard from "../../Resorts/components/ResortCard";
import Section from "../components/Section";
import ArticleCard from "../../../common/components/ArticleCard";
import {
  ROUTE_RESORTS_AZ,
  ROUTE_RESORT_LEAGUE_TABLE,
} from "../../../common/routes";
import { ROUTE_ARTICLES } from "../../Articles/routes";
import MetaTags from "../../../layout/MetaTags";

const HomePage = () => {
  const { homePage } = useLoaderData() as HomePageData;
  const { brands } = homePage;
  const splashImageUrl = getMediaUrl(homePage.splashImage.attributes.url);
  return (
    <>
      <MetaTags
        title={"SNOMAD: Compare Ski Resorts"}
        description={
          "Find the best ski resort for you based on reviews and ratings, combined with detailed resort information and statistics."
        }
      />
      <div
        className={styles.splash}
        style={{
          backgroundImage: `url("${splashImageUrl}")`,
        }}
      >
        <h1 className={styles.splashTitle}>{homePage.title}</h1>
        <h2 className={styles.tagline}>{homePage.tagline}</h2>
        <div className={styles.topSearches}>
          <div className={styles.searchesBar}>
            <hr />
          </div>
          <div className={styles.searchesText}>Top searches</div>
          <div className={styles.searchesBar}>
            <hr />
          </div>
        </div>
        <div className={styles.locations}>
          {homePage.featuredLocations.map((location) => {
            return (
              <Link
                key={location.id}
                className={styles.location}
                to={`${ROUTE_RESORTS_AZ}/${location.slug}`}
              >
                {location.locationName}
              </Link>
            );
          })}
        </div>
      </div>
      <Section title={"Featured Resorts"}>
        {homePage.featuredResorts.map((featuredResort: CompiledResort) => {
          return (
            <ResortCard
              key={featuredResort.id}
              resort={featuredResort}
              withRoundedBorders={true}
            />
          );
        })}
      </Section>
      <Section
        title={"Top Ranked Resorts"}
        ctaLink={`${ROUTE_RESORT_LEAGUE_TABLE}/overall`}
        ctaText={"See Best Resorts"}
      >
        {homePage.topRankedSkiResorts.map(
          (topRankedSkiResort: CompiledResort, index: number) => {
            return (
              <ResortCard
                key={topRankedSkiResort.id}
                resort={topRankedSkiResort}
                withRoundedBorders={true}
                position={index + 1}
              />
            );
          },
        )}
      </Section>

      <Section
        title={"Resort League tables"}
        ctaLink={ROUTE_RESORT_LEAGUE_TABLE}
        ctaText={"View All League Tables"}
      >
        {homePage.featuredResortLeagueTables.map(
          (featuredResortLeagueTable) => {
            return (
              <InfoCard
                key={featuredResortLeagueTable.id}
                splashImage={featuredResortLeagueTable.splashImage}
                linkUrl={`${ROUTE_RESORT_LEAGUE_TABLE}/${featuredResortLeagueTable.slug}`}
                withRoundedBorders
              >
                {featuredResortLeagueTable.name}
              </InfoCard>
            );
          },
        )}
      </Section>

      <Section
        title={"Tips & Guides"}
        variant={"gradient"}
        ctaLink={ROUTE_ARTICLES}
        ctaText={"All Guides & Tips"}
      >
        {homePage.articles.map((article) => {
          return (
            <ArticleCard
              key={article.id}
              linkUrl={`${ROUTE_ARTICLES}/${article.slug}`}
              title={article.title}
              preview={article.preview}
              splashImage={article.image}
            />
          );
        })}
      </Section>
    </>
  );
};

export default HomePage;
