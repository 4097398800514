import React from "react";
import styles from "./Navigation.module.scss";
import { Link, NavLink } from "react-router-dom";
import { ROUTE_RESORT_LEAGUE_TABLE, ROUTE_RESORTS_AZ } from "../common/routes";
import config from "../config";

const { siteNavigationLeagueTables, siteNavigationAZLocations } =
  config.siteConfig;
console.log({ siteNavigationAZLocations });
const getActiveClassName = ({ isActive }: { isActive: boolean }) =>
  isActive ? styles.active : "";
const Navigation = () => {
  return (
    <nav className={styles.navigation}>
      <div className={styles.dropdownContainer}>
        <NavLink to="/" className={getActiveClassName}>
          Home
        </NavLink>
      </div>
      <div className={styles.dropdownContainer}>
        <NavLink to={ROUTE_RESORT_LEAGUE_TABLE} className={getActiveClassName}>
          Resort Rankings
        </NavLink>
        <div className={styles.dropdownContent}>
          {siteNavigationLeagueTables.map((siteNavigationLeagueTable) => {
            return (
              <Link
                to={siteNavigationLeagueTable.href}
                key={siteNavigationLeagueTable.label}
              >
                {siteNavigationLeagueTable.label}
              </Link>
            );
          })}
        </div>
      </div>
      <div className={styles.dropdownContainer}>
        <NavLink to={ROUTE_RESORTS_AZ} className={getActiveClassName}>
          Resorts A-Z
          <div className={styles.dropdownContent}>
            {siteNavigationAZLocations.map((siteNavigationLeagueTable) => {
              return (
                <Link
                  to={siteNavigationLeagueTable.href}
                  key={siteNavigationLeagueTable.label}
                >
                  {siteNavigationLeagueTable.label}
                </Link>
              );
            })}
          </div>
        </NavLink>
      </div>
      <div className={styles.dropdownContainer}>
        <NavLink to="/tips-and-guides" className={getActiveClassName}>
          Guides
        </NavLink>
      </div>
    </nav>
  );
};

export default Navigation;
