import React from "react";
import styles from "./LeagueFilters.module.scss";
import Button from "../../../common/components/Button";
import { ROUTE_RESORTS_AZ } from "../../../common/routes";

interface Props {}
const LeagueFilters = ({}: Props) => {
  return (
    <>
      <div className={styles.lineOne}>
        <Button
          variant={"dark"}
          className={styles.button}
          linkTo={`${ROUTE_RESORTS_AZ}/europe`}
        >
          Europe
        </Button>
        <Button
          variant={"dark"}
          className={styles.button}
          linkTo={`${ROUTE_RESORTS_AZ}/north-america`}
        >
          North America
        </Button>
        <Button variant={"dark"} className={styles.button}>
          Southern Hemisphere
        </Button>
      </div>
      <hr />
      <div className={styles.lineTwo}>
        <Button variant={"dark"} className={styles.button}>
          {" "}
          Europe
        </Button>
        <Button variant={"dark"} className={styles.button}>
          North America
        </Button>
      </div>
    </>
  );
};

export default LeagueFilters;
