import React from "react";
import styles from "./ResortStatGraphic.module.scss";
import { Rating } from "../../types";
import graphic from "./icons/beginner.svg";
import RatingComponent from "../../../../common/components/Rating";

interface Props {
  title: string;
  rating: Rating;
}

const ResortStatGraphic = ({ rating, title }: Props) => {
  return (
    <div className={styles.resortStatGraphic}>
      <img src={graphic} className={styles.icon} />
      <div className={styles.stats}>
        <div className={styles.statName}>{title}</div>
        <RatingComponent rating={rating.rating} />
      </div>
    </div>
  );
};

export default ResortStatGraphic;
