import Rating from "../../../common/components/Rating";
import React from "react";
import InfoCard from "../../../common/components/InfoCard";
import { CompiledResort } from "../types";
import styles from "./ResortCard.module.scss";
import { ROUTE_RESORT } from "../../../common/routes";
import getNumberOrdinal from "../../../common/utils/getNumberOrdinal";

interface Props {
  resort: CompiledResort;
  withRoundedBorders?: boolean;
  position?: number;
}

const ResortCard = ({ resort, position }: Props) => {
  return (
    <InfoCard
      linkUrl={`${ROUTE_RESORT}/${resort.slug}`}
      withRoundedBorders
      splashImage={resort.splashImage}
      logoImage={resort.resortLogo}
    >
      <div className={styles.resortDataWrapper}>
        {position ? (
          <div className={styles.position}>{getNumberOrdinal(position)}</div>
        ) : (
          ""
        )}
        <div className={styles.resortInfo}>
          <span className={styles.resortLocation}>
            {resort.location.locationName}
          </span>
          <span className={styles.resortName}>{resort.name}</span>
        </div>
        <div>
          <Rating rating={resort.ratingOverall?.rating} size={"medium"} />
          <div className={styles.reviews}>
            {resort.ratingOverall?.rating.toFixed(1)} (
            {resort.ratingOverall?.numReviews ?? "no"} reviews)
          </div>
        </div>
      </div>
    </InfoCard>
  );
};
export default ResortCard;
