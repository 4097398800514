import React from "react";
import ReactDOM from "react-dom/client";
import "./layout/global.scss";
import reportWebVitals from "./reportWebVitals";
import brandRoutes from "./modules/Brands/routes";
import offersRoutes from "./modules/Offers/routes";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import articlesRoutes from "./modules/Articles/routes";
import resortsRoutes from "./modules/Resorts/routes";
import homePageRoutes from "./modules/HomePage/routes";

const router = createBrowserRouter([
  homePageRoutes,
  brandRoutes,
  offersRoutes,
  articlesRoutes,
  ...resortsRoutes,
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
