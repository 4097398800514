import App from "../../../App";
import Brands from "../components/Brands";
import BrandPage from "../pages/BrandPage";
import React from "react";
import fetchBrands from "../data/fetchBrands";

const brandsLoader = async ({ params }: any) => {
  const brandData = await fetchBrands({ params });
  return { brands: brandData };
};

const brandLoader = async ({ params }: any) => {
  const { slug } = params;
  const brandData = await fetchBrands({ params });
  const currentBrand = brandData.find((brand: any) => brand.slug === slug);
  return { brand: currentBrand };
};

const brandsRoutes = {
  path: "/brands",
  element: <App />,
  children: [
    {
      path: "/brands",
      element: <Brands />,
      loader: brandsLoader,
    },
    {
      path: "/brands/:slug",
      element: <BrandPage />,
      loader: brandLoader,
    },
  ],
};

export default brandsRoutes;
