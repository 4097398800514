import React, { useState } from "react";
import styles from "./Offers.module.scss";
import { useLoaderData } from "react-router-dom";
import { Brands, Brand } from "../../Brands/types";
import { Offers as OffersType } from "../types";
import OfferCaption from "./OfferCaption";
import InfoCard from "../../../common/components/InfoCard";

const getUniqueFilters = (offers: OffersType) => {
  const offerTypes: any = {};
  const location: any = {};
  offers.forEach((offer: any) => {
    offerTypes[offer.type] = true;
    // location[offer.location] = true
  });
  return {
    offerTypes: Object.keys(offerTypes),
  };
};

const getUniqueBrands = (brands: Brands) => {
  const uniqueBrands: { [name: string]: boolean } = {};
  brands.forEach((brand) => {
    uniqueBrands[brand.name] = true;
  });
  return Object.keys(uniqueBrands);
};

const getFilters = (
  availableOptions: string[],
  selectedOption: string | null,
  setFilterFunction: any,
  title: string,
) => {
  return (
    <>
      <h3>{title}</h3>
      {availableOptions.map((option: string) => {
        const isSelected = selectedOption === option;
        return (
          <div
            key={option}
            className={`${styles.pill}`}
            style={{ backgroundColor: isSelected ? "red" : "" }}
            onClick={() => setFilterFunction(isSelected ? null : option)}
          >
            {option}
          </div>
        );
      })}
    </>
  );
};

const Offers = (props: any) => {
  const data: any = useLoaderData();
  const { brands, offers } = data as { offers: OffersType; brands: Brands };

  const { offerTypes } = getUniqueFilters(offers);
  const [offerFilter, setOfferFilter] = useState(null);
  const uniqueBrands = getUniqueBrands(brands);
  const [selectedBrand, setBrandsFilter] = useState(null);

  return (
    <div className="content">
      {/*<h2>Filters</h2>*/}
      {/*{getFilters(offerTypes, offerFilter, setOfferFilter, "Offer Category")}*/}
      {/*{getFilters(uniqueBrands, selectedBrand, setBrandsFilter, "Brand")}*/}
      <h1>Offers</h1>
      <div className={styles.tiles}>
        {offers
          .filter((offer) => {
            return offerFilter === null || offer.type === offerFilter;
          })
          .filter((offer) => {
            const brand = brands.find(
              (brand) => brand.name === selectedBrand,
            ) as Brand;
            return brand === undefined || offer.brandId === brand.id;
          })
          .sort((offerA, offerB) => {
            // sticky offers first
            return offerA.isSticky === offerB.isSticky
              ? 0
              : offerA.isSticky
              ? -1
              : 1;
          })
          .map((offer) => {
            const brand = brands.find(
              (brand) => brand.id === offer.brandId,
            ) as Brand;
            return (
              <InfoCard
                key={brand.id}
                linkUrl={`/brands/${brand.slug}`}
                splashImage={brand.backgroundImage}
                logoImage={brand.logo}
                withRoundedBorders={true}
              >
                <OfferCaption offer={offer} brand={brand} />
              </InfoCard>
            );
          })}
      </div>
    </div>
  );
};

export default Offers;
