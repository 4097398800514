import strapiClient from "../../../common/strapi/strapiClient";
import {
  CompiledResort,
  Resort,
  ResortLeagueTable,
  ResortRating,
  ResortsPage,
} from "../types";
import {
  StrapiData,
  StrapiId,
  StrapiImageResponse,
  StrapiResponse,
  StrapiStandardFields,
} from "../../../common/strapi/types";
import { SkiLocation } from "../../Locations/types";
import apiClient from "../../../common/apiClient/apiClient";
import mapImage from "../../../common/strapi/mapImage";

export type ResortPageStrapiData<MyType> = {
  id: StrapiId;
  attributes: MyType & {
    featuredResorts: StrapiResponse<Resort>;
    splashImage: StrapiImageResponse;
    publishedAt: string;
    updatedAt: string;
  };
};

// NB this is correct mapper
export const mapLocation = (
  skiLocationResponse: StrapiData<SkiLocation>,
): SkiLocation => {
  return {
    ...skiLocationResponse.attributes,
    id: skiLocationResponse.id,
  };
};

const mapLocations = (response: any): SkiLocation[] => {
  return response.map(mapLocation);
};
export type ResortData = {
  id: number;
  attributes: Resort &
    StrapiStandardFields & {
      location: StrapiResponse<SkiLocation>;
      splashImage: StrapiImageResponse;
      resortLogo: StrapiImageResponse;
    };
};
export type ResortRatingData = {
  id: number;
  attributes: ResortRating &
    StrapiStandardFields & {
      skiResort: StrapiResponse<Resort>;
    };
};

// NB this is correct mapper
export const mapResort = (resortResponse: ResortData): Resort => {
  return {
    ...resortResponse.attributes,
    id: resortResponse.id,
    location: mapLocation(resortResponse.attributes.location.data),
    splashImage: mapImage(resortResponse.attributes.splashImage),
    resortLogo: mapImage(resortResponse.attributes.resortLogo),
  };
};
// NB this is correct mapper
export const mapResortRating = (
  resortRatingResponse: ResortRatingData,
): ResortRating => {
  return {
    ...resortRatingResponse.attributes,
    id: resortRatingResponse.id,
  };
};

// NB correct mapper
export const mapResortLeagueTable = (
  resortLeagueTableResponse: ResortPageStrapiData<ResortLeagueTable>,
): ResortLeagueTable => {
  return {
    ...resortLeagueTableResponse.attributes,
    id: resortLeagueTableResponse.id,
    sortedResorts: [],
    splashImage: mapImage(resortLeagueTableResponse.attributes.splashImage),
  };
};

export type ResortLeagueTablePage = {
  resortLeagueTable: ResortLeagueTable;
};
export const fetchResortLeagueTable = async (
  slug: string,
): Promise<ResortLeagueTablePage> => {
  const response = await apiClient.get(`/resort-league-table/${slug}.json`);
  return {
    resortLeagueTable: response.data as ResortLeagueTable,
  };
};

export type ResortLeagueTablesPage = {
  resortLeagueTables: ResortLeagueTable[];
};
export const fetchResortLeagueTablesPage =
  async (): Promise<ResortLeagueTablesPage> => {
    const response = await apiClient.get(`/pages/resort-league-tables.json`);
    return {
      resortLeagueTables: response.data
        .resortLeagueTables as ResortLeagueTable[],
    };
  };
export type ResortsAZPageData = {
  resorts: CompiledResort[];
  slug?: string;
};
export const fetchResortsAZ = async (
  slug?: string,
): Promise<ResortsAZPageData> => {
  const response = await apiClient.get(`/resorts-az/${slug ?? "index"}.json`);
  return {
    resorts: response.data as CompiledResort[],
    slug,
  };
};

export const fetchResortLeagueTables = async (): Promise<
  ResortLeagueTable[]
> => {
  const resortLeagueTables = await strapiClient.get(
    "/api/resort-league-tables?populate=*",
  );
  return resortLeagueTables.data.data.map(mapResortLeagueTable);
};

export const fetchLocations = async (): Promise<SkiLocation[]> => {
  const locations = await strapiClient.get("/api/locations?populate=*");
  return mapLocations(locations.data.data);
};

export type ResortsPageType = {
  resortsPage: ResortsPage;
  locations: SkiLocation[];
  resorts: CompiledResort[];
  resortLeagueTables: ResortLeagueTable[];
  resortLeagueTable?: ResortLeagueTable;
};

export const fetchResort = async (slug: string): Promise<CompiledResort> => {
  const response = await apiClient.get(`/resort/${slug}.json`);
  return response.data;
};
