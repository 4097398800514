import App from "../../../App";
import Offers from "../components/Offers";
import React from "react";
import fetchOffers from "../data/fetchOffers";
import fetchBrands from "../../Brands/data/fetchBrands";

const offersLoader = async ({ params }: any) => {
  const offers = await fetchOffers();
  const brands = await fetchBrands({ params });
  return { offers, brands };
};

export const ROUTE_OFFERS = "/offers";

const offersRoutes = {
  path: ROUTE_OFFERS,
  element: <App />,
  children: [
    {
      path: ROUTE_OFFERS,
      element: <Offers />,
      loader: offersLoader,
    },
  ],
};

export default offersRoutes;
