import React from "react";
import styles from "./Header.module.scss";
import Logo from "./big-logo.webp";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";
import Button from "../common/components/Button";

const Header = () => {
  return (
    <header>
      <div className={styles.Header}>
        <div>
          <Link to={"/"}>
            <img src={Logo} className={styles.logo} />
          </Link>
        </div>
        <div className={styles.navigationDesktop}>
          <Navigation />
        </div>
        <div className={styles.right}>
          <Button>Leave a Review</Button>
        </div>
      </div>
      <div className={styles.navigationMobile}>
        <Navigation />
      </div>
    </header>
  );
};

export default Header;
