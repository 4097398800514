import App from "../../../App";
import React from "react";
import HomePage from "../pages/HomePage";
import fetchPage from "../data/fetchPage";

export const homePageLoader = async ({ params }: any) => {
  return await fetchPage(params);
};

const homePageRoutes = {
  path: "/",
  element: <App />,
  children: [
    {
      path: "/",
      element: <HomePage />,
      loader: homePageLoader,
    },
  ],
};

export default homePageRoutes;
