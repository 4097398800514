import React from "react";
import styles from "./OfferCaption.module.scss";
import { Offer } from "../types";
import { Brand } from "../../Brands/types";

interface Props {
  offer: Offer;
  brand: Brand;
}
const OfferCaption = ({ offer, brand }: Props) => {
  return (
    <div className={styles.OfferCaption}>
      <div className={styles.caption}>{offer.description}</div>
      {/*<div className={styles.pill}>{offer.type}</div>*/}
      {/*<div className={styles.pill}>{brand.name}</div>*/}
      {/*{offer.isSticky ? <div className={styles.sticky}></div> : ""}*/}
    </div>
  );
};

export default OfferCaption;
