import React from "react";
import { useLoaderData, Link } from "react-router-dom";
import { CompiledFields, CompiledResort, Rating as RatingData } from "../types";
import styles from "./ResortInfoPage.module.scss";
import Markdown from "react-markdown";
import WriteReview from "../components/ReviewForm/WriteReview";
import Splash from "../../../common/components/Splash";
import { ROUTE_RESORT } from "../../../common/routes";
import getMediaUrl from "../../../common/utils/getMediaUrl";
import ResortStatGraphic from "../components/ResortStatGraphic/ResortStatGraphic";
import Button from "../../../common/components/Button";
import classNames from "classnames";
import Section from "../../HomePage/components/Section";
import ResortReviewPreview from "../components/ResortReviewPreview/ResortReviewPreview";
import ResortStats from "../components/ResortStats/ResortStats";
import { Helmet } from "react-helmet";
import Rating from "../../../common/components/Rating";
import getNumberOrdinal from "../../../common/utils/getNumberOrdinal";
import MetaTags from "../../../layout/MetaTags";

type Ratings = keyof CompiledFields;
const ratingsToLabels: { [key in Ratings]?: string } = {
  ratingOverall: "Overall Rating",
  ratingTerrainPark: "Park Rating",
  ratingApres: "Apres ski",
  ratingFamilyFriendly: "Family Friendly",
  ratingOffPiste: "Off Piste",
  ratingEatingOut: "Eating out",
  ratingValue: "Value for money",
  ratingTreeSkiing: "Tree Skiing",
  ratingUncrowded: "Uncrowded",
};

const getTagLine = (resort: CompiledResort) => {
  return (
    <div>
      <div>
        <Rating rating={resort.ratingOverall?.rating} size={"medium"} />
        <div className={styles.reviews}>
          {resort.ratingOverall?.rating.toFixed(1)} (
          {resort.ratingOverall?.numReviews ?? "no"} reviews)
        </div>
      </div>
      {resort.positionOverall ? (
        <div>
          <span>Ranked</span>
          <span>
            {getNumberOrdinal(resort.positionOverall.position)} of{" "}
            {resort.positionOverall.numTotalResorts}
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const ResortInfoPage = () => {
  const { resort } = useLoaderData() as { resort: CompiledResort };

  return (
    <>
      <MetaTags
        title={`${resort.name} Ski Resort Guide: Reviews, Ratings and Stats`}
        description={`Browse the latest ${resort.name} reviews & ratings. Plus, detailed resort stats including ski terrain breakdown, ski lift info, costs and average snowfall data. The ultimate guide to slopes, amenities and the skiing experience in ${resort.name}.`}
      />
      <Splash
        splashText={`${resort.name} reviews & ratings`}
        tagline={getTagLine(resort)}
      />
      <div className={"content"}>
        <div className={styles.breadcrumbs}>
          <Link to={ROUTE_RESORT}>Resorts</Link> -&gt; {resort.name}
        </div>
        <div className={styles.headlineStats}>
          <div className={styles.headlineStatsStats}>
            {Object.keys(ratingsToLabels).map((ratingName) => {
              // @ts-ignore
              if (!resort[ratingName as keyof CompiledResort]) {
                // @ts-ignore
                return null;
              }
              return (
                <ResortStatGraphic
                  key={ratingName}
                  rating={
                    resort[ratingName as keyof CompiledResort] as RatingData
                  }
                  // @ts-ignore
                  title={ratingsToLabels[ratingName as any] as string}
                />
              );
            })}
          </div>
          <div
            className={styles.headlineStatsImage}
            style={{
              backgroundImage: `url("${getMediaUrl(
                resort?.splashImage?.attributes.url,
              )}")`,
            }}
          />
        </div>
      </div>
      <div className={styles.tabsContainer}>
        <div className={classNames("content", styles.tabs)}>
          <div className={styles.links}>
            <a href={"#"}>Overview</a>
            <a href={"#"}>Resort Stats</a>
            <a href={"#"}>Reviews</a>
            <a href={"#"}>Contact</a>
          </div>
          <div className={styles.reviewButton}>
            <Button>Leave a review</Button>
          </div>
        </div>
      </div>

      <div className={classNames(styles.description, "content")}>
        <Markdown>{resort.description}</Markdown>
      </div>

      <div className={"content"}>
        <ResortStats resort={resort} />
      </div>
      <div className={"content"}>
        <WriteReview resort={resort} />
      </div>
      <div className={"content"}>
        <div className="pageTitle">All Reviews</div>
        {resort.featuredReviews.map((resortReview) => {
          return (
            <ResortReviewPreview
              resortRating={resortReview}
              key={resortReview.id}
            />
          );
        })}
      </div>
    </>
  );
};

export default ResortInfoPage;
