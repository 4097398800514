import { HomePage as HomePageType, HomePage } from "../types";
import { CompiledResort, Resort, ResortLeagueTable } from "../../Resorts/types";
import { StrapiId, StrapiImageResponse } from "../../../common/strapi/types";
import { Offer } from "../../Offers/types";
import apiClient from "../../../common/apiClient/apiClient";

export type StrapiData<MyType> = {
  id: StrapiId;
  attributes: MyType & {
    publishedAt: string;
    updatedAt: string;
  };
};
export type HomePageStrapiData<MyType> = {
  id: StrapiId;
  attributes: MyType & {
    featuredResorts: StrapiResponse<CompiledResort[]>;
    featuredResortLeagueTables: StrapiResponse<ResortLeagueTable[]>;
    topRankedSkiResorts: StrapiResponse<Resort[]>;
    featuredOffers: StrapiResponse<Offer[]>;
    splashImage: StrapiImageResponse;
    publishedAt: string;
    updatedAt: string;
  };
};

export type StrapiResponse<MyType> = {
  data: StrapiData<MyType>;
};

export type HomePageData = {
  homePage: HomePageType;
};
const fetchPage = async (params: any): Promise<HomePageData> => {
  const response = await apiClient.get(`/pages/home.json`);
  return {
    homePage: response.data,
  };
};

export default fetchPage;
