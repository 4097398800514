import strapiClient from "../../../common/strapi/strapiClient";
import { Brand } from "../types";
import { StrapiImageResponse } from "../../../common/strapi/types";

export type StrapiData<MyType> = {
  id: number;
  attributes: MyType & {
    publishedAt: string;
    updatedAt: string;
    logo: StrapiImageResponse;
    backgroundImage: StrapiImageResponse;
  };
};

export const mapBrand = (brandResponse: StrapiData<Brand>): Brand => {
  const { name, description, pageTitle, url, discountCode, slug } =
    brandResponse.attributes;
  return {
    id: brandResponse.id,
    name,
    description,
    pageTitle,
    url,
    discountCode,
    slug,
    logo: brandResponse.attributes.logo.data,
    backgroundImage: brandResponse.attributes.backgroundImage.data,
  };
};

const fetchBrands = async ({ params }: any) => {
  const brands = await strapiClient.get(`/api/brands?populate=*`);

  const mappedBrands = brands.data.data.map(mapBrand);

  return mappedBrands;
};

export default fetchBrands;
