import applicationConfig, { ApplicationConfig } from "../../config";

const getMediaUrl = (
  mediaPath?: string,
  config: ApplicationConfig = applicationConfig,
): string | undefined => {
  if (!mediaPath) {
    return undefined;
  }
  return `${config.media.baseUrl}${mediaPath}`;
};

export default getMediaUrl;
