import React, { ChangeEvent, ChangeEventHandler, ReactNode } from "react";
import {
  CompiledResort,
  ResortRating,
  RiderLevels,
  RiderType,
} from "../../types";

import RatingRow from "./RatingRow";
import submitReview, { updateReview } from "./submitReview";
import Button from "../../../../common/components/Button";
import SelectRow from "./SelectRow";
import InputRow from "./InputRow";
import styles from "./WriteReview.module.scss";
import TextAreaRow from "./TextAreaRow";
import { countryList } from "../../../../common/utils/countryList";
import CheckboxRow from "./CheckboxRow";

interface Props {
  resort: CompiledResort;
}

const levelOptions: { name: string; value: RiderLevels | "" }[] = [
  { name: "", value: "" },
  { name: "beginner", value: "beginner" },
  { name: "intermediate", value: "intermediate" },
  { name: "advanced", value: "advanced" },
  { name: "expert", value: "expert" },
];
const countryOptions: { name: string; value: string | "" }[] = [
  { name: "", value: "" },
  ...countryList.map((countryName) => {
    return { name: countryName, value: countryName };
  }),
];
const riderTypeOptions: {
  name: string;
  value: RiderType | "";
}[] = [
  { name: "", value: "" },
  { name: "Skier", value: "skier" },
  { name: "Snowboarder", value: "snowboarder" },
];

const ratings: { label: string; name: keyof ResortRating }[] = [
  { label: "Off-piste", name: "ratingOffPiste" },
  { label: "Terrain Parks", name: "ratingTerrainPark" },
  { label: "Family Friendly", name: "ratingFamilyFriendly" },
  { label: "Tree Skiing", name: "ratingTreeSkiing" },
  { label: "Value", name: "ratingValue" },

  { label: "Aprés Ski", name: "ratingApres" },
  { label: "Eating out", name: "ratingEatingOut" },
  { label: "Uncrowded", name: "ratingUncrowded" },
  { label: "Lifts", name: "ratingLifts" },
];

const requiredFields: { [formSection: number]: string[] } = {
  1: ["ratingOverall", "riderType", "riderLevel"],
  2: ["reviewText", "userName"],
  3: ["userCountry", "userEmail"],
};

class WriteReview extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      formState: {
        fields: {},
      },
      section: 1,
    };
  }
  getSetFormStateFnc = (fieldName: string) => {
    return (newValue: number | string) =>
      this.setState({
        formState: {
          fields: {
            ...this.state.formState.fields,
            [fieldName]: newValue,
          },
        },
      });
  };
  getHandleOnChange = (
    fieldName: string,
  ): ChangeEventHandler<HTMLSelectElement> => {
    return (
      event: React.FormEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
    ) => {
      // @ts-ignore
      if (event.target.type === "checkbox") {
        return this.setState({
          formState: {
            fields: {
              ...this.state.formState.fields,
              // @ts-ignore
              [fieldName]: Boolean(event.target.checked),
            },
          },
        });
      }
      this.setState({
        formState: {
          fields: {
            ...this.state.formState.fields,
            // @ts-ignore
            [fieldName]: event.target.value,
          },
        },
      });
    };
  };
  getFormState = (fieldName: string): string => {
    return this.state.formState.fields[fieldName] ?? "";
  };
  submitForm = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (this.state.reviewId) {
      const review = await updateReview(
        this.state.reviewId,
        this.state.formState.fields,
      );
      this.setState({ section: this.state.section + 1, reviewId: review.id });
    } else {
      const review = await submitReview(
        this.props.resort.id,
        this.state.formState.fields,
      );
      this.setState({ section: this.state.section + 1, reviewId: review.id });
    }
  };
  renderSection = (section: number) => {
    const sections: { [key: number]: ReactNode } = {
      1: (
        <>
          <RatingRow
            name={"ratingOverall"}
            label={"Overall Rating"}
            isRequired
            value={Number(this.getFormState("ratingOverall"))}
            updateValue={this.getSetFormStateFnc("ratingOverall")}
          />
          <div className={styles.columns}>
            <div className={styles.column}>
              <SelectRow
                name={"riderType"}
                label={"Rider Type"}
                value={this.getFormState("riderType")}
                updateValue={this.getHandleOnChange("riderType")}
                options={riderTypeOptions}
                isRequired
              />
            </div>
            <div className={styles.column}>
              <SelectRow
                name={"riderLevel"}
                label={"Rider Level"}
                options={levelOptions}
                value={this.getFormState("riderLevel")}
                updateValue={this.getHandleOnChange("riderLevel")}
                isRequired
              />
            </div>
          </div>
          <div className={styles.columns}>
            Please <b>ONLY</b> rate the categories below that are relevant to
            you.
          </div>
          <div className={styles.columns}>
            <div className={styles.column}>
              {ratings.slice(0, ratings.length / 2).map((rating) => {
                return (
                  <RatingRow
                    key={rating.name}
                    name={rating.name}
                    label={rating.label}
                    value={Number(this.getFormState(rating.name))}
                    updateValue={this.getSetFormStateFnc(rating.name)}
                  />
                );
              })}
            </div>
            <div className={styles.column}>
              {ratings.slice(ratings.length / 2).map((rating) => {
                return (
                  <RatingRow
                    key={rating.name}
                    name={rating.name}
                    label={rating.label}
                    value={Number(this.getFormState(rating.name))}
                    updateValue={this.getSetFormStateFnc(rating.name)}
                  />
                );
              })}
            </div>
          </div>
          <Button type={"submit"} variant={"fullWidth"}>
            Go
          </Button>
        </>
      ),
      2: (
        <>
          <div className={styles.formTitle}>And finally...</div>
          <TextAreaRow
            name={"reviewText"}
            label={"Please share a full review"}
            value={this.getFormState("reviewText")}
            updateValue={this.getHandleOnChange("reviewText")}
            isRequired
          />
          <InputRow
            name={"userName"}
            label={"First Name"}
            value={this.getFormState("userName")}
            updateValue={this.getHandleOnChange("userName")}
            isRequired
          />
          <Button type={"submit"} variant={"fullWidth"}>
            Go
          </Button>
        </>
      ),
      3: (
        <>
          <div className={styles.formTitle}>
            Verify youre a real snow slider (and not a pesky bot!)
          </div>
          <SelectRow
            name={"userCountry"}
            label={"Your Country"}
            options={countryOptions}
            value={this.getFormState("userCountry")}
            updateValue={this.getHandleOnChange("userCountry")}
            isRequired
          />
          <InputRow
            name={"email"}
            label={"Your email address"}
            value={this.getFormState("userEmail")}
            updateValue={this.getHandleOnChange("userEmail")}
            isRequired
          />
          <CheckboxRow
            name={"subscribe"}
            label={
              "Get free access to resort ratings & 🔥 ski content during the season by email. Unsubscribe at anytime."
            }
            value={this.getFormState("shouldSubscribe")}
            updateValue={this.getHandleOnChange("shouldSubscribe")}
          />
          <Button type={"submit"} variant={"fullWidth"}>
            Go
          </Button>
        </>
      ),
      4: <div>Thanks for the review</div>,
    };
    return sections[section];
  };
  render = () => {
    const { resort } = this.props;
    return (
      <form onSubmit={this.submitForm}>
        <h2>Give your opinion on {resort.name}</h2>
        {this.renderSection(this.state.section)}
      </form>
    );
  };
}

export default WriteReview;
