import React from "react";
import styles from "./Brands.module.scss";
import { useLoaderData } from "react-router-dom";
import { Brand } from "../types";
import InfoCard from "../../../common/components/InfoCard";

const Brands = (props: any) => {
  const data = useLoaderData();
  const { brands } = data as any;
  return (
    <div className={styles.Brands}>
      <h1>Brands</h1>
      <div className={styles.tiles}>
        {brands.map((brand: Brand) => {
          return (
            <InfoCard
              key={brand.id}
              linkUrl={`/brands/${brand.slug}`}
              splashImage={brand.backgroundImage}
              logoImage={brand.logo}
            >
              {brand.description}
            </InfoCard>
          );
        })}
      </div>
    </div>
  );
};

export default Brands;
