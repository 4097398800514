import React, { ReactNode } from "react";
import styles from "./InfoCard.module.scss";
import classNames from "classnames";
import getMediaUrl from "../utils/getMediaUrl";
import { StrapiImage } from "../strapi/types";
import { Link } from "react-router-dom";

interface Props {
  caption?: string | React.ReactElement;
  linkUrl: string;
  withRoundedBorders?: boolean;
  children: ReactNode;
  splashImage?: StrapiImage | null;
  logoImage?: StrapiImage | null;
  theme?: "light" | "dark";
}

const InfoCard = ({
  withRoundedBorders,
  linkUrl,
  splashImage,
  logoImage,
  children,
  theme = "light",
}: Props) => {
  const captionStyles = classNames(styles.caption, {
    [styles.roundedCorners]: withRoundedBorders,
    [styles.darkThemeCaption]: theme === "dark",
  });
  return (
    <Link to={linkUrl} className={styles.link}>
      <div
        style={{
          backgroundImage: splashImage
            ? `url("${getMediaUrl(splashImage.attributes.url)}")`
            : undefined,
        }}
        className={classNames(styles.backgroundImage, {
          [styles.roundedCorners]: withRoundedBorders,
        })}
      >
        {logoImage ? (
          <img
            src={getMediaUrl(logoImage.attributes.url)}
            className={styles.logo}
          />
        ) : (
          ""
        )}
      </div>
      <div className={captionStyles}>{children}</div>
    </Link>
  );
};
export default InfoCard;
