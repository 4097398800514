import React from "react";
import styles from "./FormStyles.module.scss";
import Rating from "../../../../common/components/Rating";

interface Props {
  name: string;
  label: string;
  isRequired?: boolean;
  value: number;
  updateValue: (newValue: string) => void;
}

const RatingRow = ({
  name,
  label,
  isRequired = false,
  value,
  updateValue,
}: Props) => {
  return (
    <div className={styles.formRow}>
      <label htmlFor={name} className={styles.label}>
        {label}
        {isRequired ? <span className={styles.isRequired}>*</span> : ""}
      </label>
      <Rating
        isInput={true}
        size={"large"}
        setRating={updateValue}
        rating={value}
      />
    </div>
  );
};

export default RatingRow;
