import React from "react";
import styles from "./ResortLeagueTablesPage.module.scss";
import { useLoaderData } from "react-router-dom";
import { ResortsPageType } from "../data/fetchPage";
import InfoCard from "../../../common/components/InfoCard";
import classNames from "classnames";
import Splash from "../../../common/components/Splash";
import { ROUTE_RESORT_LEAGUE_TABLE } from "../../../common/routes";

const ResortLeagueTablesPage = () => {
  const { resortLeagueTables } = useLoaderData() as ResortsPageType;
  return (
    <>
      <Splash
        splashText={"Ski Resort League tables"}
        tagline={
          "Browse the best ski resorts by location and by different categories ranging from best for Après Ski to snowboarding to off-piste skiing, all powered by community reviews and rating."
        }
      />
      <div className={styles.section}>
        <div className={classNames(styles.grid, "content")}>
          {resortLeagueTables.map((resortLeagueTable) => {
            return (
              <InfoCard
                key={resortLeagueTable.id}
                splashImage={resortLeagueTable.splashImage}
                linkUrl={`${ROUTE_RESORT_LEAGUE_TABLE}/${resortLeagueTable.slug}`}
                withRoundedBorders
              >
                {resortLeagueTable.name}
              </InfoCard>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ResortLeagueTablesPage;
