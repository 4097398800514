import siteConfig from "./site-config.json";

export type SiteConfig = {
  siteNavigationLeagueTables: { label: string; href: string }[];
  siteNavigationAZLocations: { label: string; href: string }[];
};

export interface ApplicationConfig {
  media: {
    baseUrl: string;
  };
  leagueTables: {
    minReviewsNeededPerCategory: number;
  };
  siteConfig: SiteConfig;
}
const getConfig = (): ApplicationConfig => {
  return {
    media: {
      baseUrl: "https://snomad-cms.jamesmoorhouse.co.uk",
    },
    leagueTables: {
      minReviewsNeededPerCategory: 3, //number of reviews needed to be considered in league tables
    },
    siteConfig: siteConfig as SiteConfig,
  };
};

export default getConfig();
