import React from "react";
import styles from "./Section.module.scss";
import Button from "../../../common/components/Button";
import classNames from "classnames";

interface Props {
  children: React.ReactNode;
  title: string;
  ctaLink?: string;
  ctaText?: string;
  variant?: "default" | "white" | "gradient";
}

const Section = ({
  children,
  title,
  ctaLink,
  ctaText,
  variant = "default",
}: Props) => {
  const variantStyle = {
    default: "",
    white: styles.sectionWhite,
    gradient: styles.sectionGradient,
  }[variant];

  return (
    <div className={classNames(styles.section, variantStyle)}>
      <div className={"content"}>
        <div className="pageTitle">{title}</div>
        <div className={styles.horizontalCardScroller}>{children}</div>
        {ctaLink && ctaText ? (
          <div className={styles.centeredButton}>
            <Button linkTo={ctaLink}>{ctaText}</Button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Section;
