import apiClient from "../../../common/apiClient/apiClient";
import { Article } from "../types";

const fetchArticles = async (): Promise<Article[]> => {
  const articles = await apiClient.get("/articles/all-articles.json");
  return articles.data;
};
export const fetchArticle = async (slug: string): Promise<Article> => {
  const articles = await apiClient.get(`/articles/${slug}.json`);
  return articles.data;
};

export default fetchArticles;
