import React from "react";
import styles from "./App.module.scss";
import Header from "./layout/Header";
import { Outlet } from "react-router-dom";
import Footer from "./layout/Footer";
import globalStyles from "./layout/global.scss";

const App = (props: any) => {
  return (
    <>
      <Header />
      <main className={styles.content}>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default App;
