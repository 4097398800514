import React from "react";
import { Link } from "react-router-dom";
import styles from "./ArticleCard.module.scss";
import getMediaUrl from "../utils/getMediaUrl";
import { StrapiImage } from "../strapi/types";

interface Props {
  linkUrl: string;
  title: string;
  preview: string;
  splashImage: StrapiImage;
}

const ArticleCard = ({ linkUrl, splashImage, title, preview }: Props) => {
  return (
    <Link to={linkUrl} className={styles.articleCard}>
      <div
        style={{
          backgroundImage: `url("${getMediaUrl(splashImage.attributes.url)}")`,
        }}
        className={styles.backgroundImage}
      ></div>
      <div className={styles.caption}>
        <div className={styles.title}>{title}</div>
        <div className={styles.preview}>{preview}</div>
      </div>
    </Link>
  );
};
export default ArticleCard;
