import React from "react";
import { Link, useLoaderData } from "react-router-dom";
import Markdown from "react-markdown";
import styles from "./ArticlePage.module.scss";
import formatDate from "../../../common/utils/formatDate";
import PostInfo from "../components/PostInfo";
import Splash from "../../../common/components/Splash";
import { ROUTE_ARTICLES } from "../routes";
import MetaTags from "../../../layout/MetaTags";

const ArticlePage = (...props: any) => {
  const data: any = useLoaderData();
  const { article } = data;
  return (
    <>
      <MetaTags
        title={`${article.title} | SNOMAD`}
        description={`${article.title} | SNOMAD`}
      />
      <Splash splashText={article.title} />
      <div className={"content"}>
        <Link to={ROUTE_ARTICLES} className={styles.allPosts}>
          All Posts
        </Link>
        <article key={article.id} className={styles.article}>
          <PostInfo article={article} />
          <h1 className={styles.title}>{article.title}</h1>
          <Markdown>{article.content}</Markdown>
        </article>
      </div>
    </>
  );
};

export default ArticlePage;
