import React from "react";
import { Rating as SimpleStarRating } from "react-simple-star-rating";
import styles from "./Rating.module.scss";
interface Props {
  rating?: number;
  showNumericValue?: boolean;
  size?: "small" | "medium" | "large";
  isInput?: boolean;
  setRating?: (rating: any) => void;
}
const noop = () => {
  console.log("set rating called but no callback passed in");
};
const Rating = ({
  rating = 0,
  showNumericValue = false,
  size = "medium",
  isInput = false,
  setRating = noop,
}: Props) => {
  const sizeToUse = {
    small: 15,
    medium: 20,
    large: 25,
  }[size];
  return (
    <>
      {showNumericValue ? (
        <span className={styles.number}>{rating.toFixed(1)}</span>
      ) : (
        ""
      )}
      <SimpleStarRating
        initialValue={rating}
        onClick={setRating}
        size={sizeToUse}
        readonly={!isInput}
        fillColor={"#DDFA62"}
      />
    </>
  );
};
export default Rating;
