import React from "react";
import styles from "./FormStyles.module.scss";

interface Props {
  name: string;
  label: string;
  isRequired?: boolean;
  value: string;
  updateValue: any;
}

const InputRow = ({
  name,
  label,
  isRequired = false,
  value,
  updateValue,
}: Props) => {
  return (
    <div className={styles.formRow}>
      <label htmlFor={name} className={styles.label}>
        {label}
        {isRequired ? <span className={styles.isRequired}>*</span> : ""}
      </label>
      <input name={name} value={value} onChange={updateValue} />
    </div>
  );
};

export default InputRow;
