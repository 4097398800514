import React from "react";
import { useLoaderData } from "react-router-dom";
import styles from "./BrandPage.module.scss";
import InfoCard from "../../../common/components/InfoCard";
import OfferCaption from "../../Offers/components/OfferCaption";

const BrandPage = () => {
  const data = useLoaderData();
  const { brand } = data as any;
  return (
    <div className={"content"}>
      <div className={styles.BrandSection}>
        <div className={styles.BrandLogoCard}>
          <InfoCard
            linkUrl={`/brands/${brand.slug}`}
            splashImage={brand.backgroundImage}
            logoImage={brand.logo}
          >
            {brand.name}
          </InfoCard>
        </div>
        <div className={styles.BrandInfo}>
          <h1>{brand.name}</h1>
          <p>{brand.description}</p>
        </div>
      </div>
    </div>
  );
};

export default BrandPage;
