import React, { ReactNode } from "react";
import styles from "./Button.module.scss";
import { Link } from "react-router-dom";

interface Props {
  children: ReactNode;
  className?: string;
  linkTo?: string;
  variant?: "default" | "dark" | "fullWidth" | "small";
  type?: "submit" | "reset" | "button";
}
const Button = ({
  children,
  variant = "default",
  className = "",
  type = "button",
  linkTo,
}: Props) => {
  if (linkTo) {
    return (
      <Link
        to={linkTo}
        className={`${styles.button} ${styles[variant]} ${className}`}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      className={`${styles.button} ${styles[variant]} ${className}`}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
