import React from "react";
import { useLoaderData } from "react-router-dom";
import { ResortsAZPageData } from "../data/fetchPage";
import Splash from "../../../common/components/Splash";
import styles from "./ResortsLeagueTablePage.module.scss";
import LeagueFilters from "../components/LeagueFilters";
import ResortsLeagueTable from "../components/ResortsLeagueTable";
import MetaTags from "../../../layout/MetaTags";

const ResortsAZPage = () => {
  const { resorts, slug } = useLoaderData() as ResortsAZPageData;

  const location = slug ?? "Worldwide";

  return (
    <>
      <MetaTags
        title={`Ski Resorts A-Z ${location}`}
        description={`Select a ski resort in ${location} to browse ski resort reviews, ratings and key information`}
      />
      <Splash splashText={`Resorts in ${location}`} />
      <div className={styles.section}>
        <div className={"content"}>
          <LeagueFilters />
        </div>
      </div>
      <ResortsLeagueTable sortedResorts={resorts} hideOrdinals />
    </>
  );
};

export default ResortsAZPage;
