import axios, { AxiosInstance } from "axios";

const tokens = {
  READ: "363c6bb40328a72f6856ac1f2d5a93c9700b0c1245b7acf1defb3a5d1a96bf1e936eacf9e4b0b7e4efa869fe054831ae7f051041a6e4dca61945d1ed88612e7ec19536c7547c75bbcf8e2355b735745d3e6a256a735568bcfd2d52a9b79f0b98de70590a2e7cee03deaa92b7eb79d65801f75290260e8f6ccb433dadb8b3ef91",
  REVIEWS:
    "177b5af7daa4b14db0a166d50d0182639e48c83103856b3fed80837606d72eaed3f7b2b6042176055a60414fc5be3902c8068dea7f9f6029820e8f83bbaad556ef350dda74ecd84ba386e198f6b50269daf3c6f29f17d4f55e552988e1e95e1fe22b808dd305d5d3fd1e76c89cd9b940a3f82f2289acd54fb0404eaff9f10743",
};

const getHeaders = (token: string = tokens.READ) => {
  return {
    Authorization: `bearer ${token}`,
  };
};

const API_URL = "https://snomad-cms.jamesmoorhouse.co.uk";

export const getStrapiClient = (token?: string): AxiosInstance => {
  return axios.create({
    headers: getHeaders(token),
    baseURL: API_URL,
  });
};

export default getStrapiClient();
export const reviewsClient = getStrapiClient(tokens.REVIEWS);
