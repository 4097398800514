import App from "../../../App";
import React from "react";
import {
  fetchResort,
  fetchResortLeagueTable,
  fetchResortLeagueTablesPage,
  fetchResortsAZ,
} from "../data/fetchPage";
import ResortInfoPage from "../pages/ResortInfoPage";
import ResortsLeagueTablePage from "../pages/ResortsLeagueTablePage";
import ResortsAZPage from "../pages/ResortsAZPage";
import ResortLeagueTablesPage from "../pages/ResortLeagueTablesPage";
import {
  ROUTE_RESORT,
  ROUTE_RESORT_LEAGUE_TABLE,
  ROUTE_RESORTS_AZ,
} from "../../../common/routes";

const resortInfoLoader = async ({ params }: any) => {
  return {
    resort: await fetchResort(params.slug),
  };
};

const resortLeagueTableLoader = async ({ params }: any) => {
  return await fetchResortLeagueTable(params.slug);
};
const resortLeagueTablesPageLoader = async () => {
  return await fetchResortLeagueTablesPage();
};
const resortsAZloader = async ({ params }: any) => {
  return await fetchResortsAZ(params.slug);
};

const resortsRoutes = [
  {
    path: ROUTE_RESORT_LEAGUE_TABLE,
    element: <App />,
    children: [
      {
        path: ROUTE_RESORT_LEAGUE_TABLE,
        element: <ResortLeagueTablesPage />,
        loader: resortLeagueTablesPageLoader,
      },
      {
        path: `${ROUTE_RESORT_LEAGUE_TABLE}/:slug`,
        element: <ResortsLeagueTablePage />,
        loader: resortLeagueTableLoader,
      },
    ],
  },
  {
    path: ROUTE_RESORTS_AZ,
    element: <App />,
    children: [
      {
        path: ROUTE_RESORTS_AZ,
        element: <ResortsAZPage />,
        loader: resortsAZloader,
      },
      {
        path: `${ROUTE_RESORTS_AZ}/:slug`,
        element: <ResortsAZPage />,
        loader: resortsAZloader,
      },
    ],
  },
  {
    path: ROUTE_RESORT,
    element: <App />,
    children: [
      {
        path: `${ROUTE_RESORT}/:slug`,
        element: <ResortInfoPage />,
        loader: resortInfoLoader,
      },
    ],
  },
];

export default resortsRoutes;
