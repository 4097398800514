import App from "../../../App";
import React from "react";
import fetchArticles, { fetchArticle } from "../data/fetchArticles";
import ArticlesPage from "../pages/ArticlesPage";
import ArticlePage from "../pages/ArticlePage";
import apiClient from "../../../common/apiClient/apiClient";

const articlesLoader = async ({ params }: any) => {
  const articles = await apiClient.get(`/articles/all-articles.json`);
  return {
    articles: articles.data,
  };
};

const articleLoader = async ({ params }: any): Promise<any> => {
  if (params.slug) {
    return {
      article: await fetchArticle(params.slug),
    };
  }
};

export const ROUTE_ARTICLES = "/tips-and-guides";

const articlesRoutes = {
  path: ROUTE_ARTICLES,
  element: <App />,
  children: [
    {
      path: ROUTE_ARTICLES,
      element: <ArticlesPage type={"blog"} />,
      loader: articlesLoader,
    },
    {
      path: `${ROUTE_ARTICLES}/:slug`,
      element: <ArticlePage type={"blog"} />,
      loader: articleLoader,
    },
  ],
};

export default articlesRoutes;
